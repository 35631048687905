import tw, { styled, css } from "twin.macro"

export const Outer = tw.div`
  relative
`

export const Inner = styled.div`
  ${tw`absolute inset-0 w-full h-full flex justify-center items-center`}
`

export const Content = styled.div`
  ${({ top, left, mobileTop, mobileLeft }) => {
    if (top || left || mobileTop || mobileLeft) {
      return tw`absolute transform`
    }
  }}

  ${({ top, mobileTop }) => {
    if (!top && mobileTop) {
      return css`
        @media screen and (max-width: 768px) {
          top: ${mobileTop}%;
        }
      `
    }
    if (top && mobileTop) {
      return css`
        @media screen and (max-width: 768px) {
          top: ${mobileTop}%;
        }
        @media screen and (min-width: 769px) {
          top: ${top}%;
        }
      `
    }
    if (top && !mobileTop) {
      return css`
        top: ${top}%;
      `
    }
    return tw``
  }}

  ${({ left, mobileLeft }) => {
    if (!left && mobileLeft) {
      return css`
        @media screen and (max-width: 768px) {
          left: ${mobileLeft}%;
        }
      `
    }
    if (left && mobileLeft) {
      return css`
        @media screen and (max-width: 768px) {
          left: ${mobileLeft}%;
        }
        @media screen and (min-width: 769px) {
          left: ${left}%;
        }
      `
    }
    if (left && !mobileLeft) {
      return css`
        left: ${left}%;
      `
    }
    return tw``
  }}

  ${({ desktopMaxWidth, mobileMaxWidth }) => {
    if (!desktopMaxWidth && mobileMaxWidth) {
      return css`
          @media screen and (max-width: 768px) {
            width: ${desktopMaxWidth}%;
          }
        `
    }
    if (desktopMaxWidth && mobileMaxWidth) {
      return css`
          @media screen and (max-width: 768px) {
            width: ${mobileMaxWidth}%;
          }
          @media screen and (min-width: 769px) {
            width: ${desktopMaxWidth}%;
          }
        `
    }
    if (desktopMaxWidth && !mobileMaxWidth) {
      return css`
          width: ${desktopMaxWidth}%;
        `
    }
    return tw``
}}
`
export const MobileDotWrapper = tw.a`
  block p-0-2
`
export const MobileDot = tw.div`
  bg-white p-0-8 border-white rounded-full
`
